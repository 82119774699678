import React from "react"
import { graphql } from "gatsby"
import Hero from "../components/hero"
import Layout from "../components/layout"
import Form from "../components/form"
import { Box, Container, Grid, Themed } from "theme-ui"
import Seo from "../components/seo"

const Contact = ({ data }) => {

  const hero = {
    'textintro': '',
    'title': 'Contact.',
    'squares': ['gray.6', 'gray.6', 'gray.6'],
    'squaresmove': false,
  }

  return (
    <Layout>
      <Seo
        title={data.site.siteMetadata.title}
        description="Contact Nemeda"
      />
      <Hero
        bg='linear-gradient(180deg, #1e1e1e, #000000)'
        color='gray.1'
        textintro={hero.textintro}
        title={hero.title}
        squares={hero.squares}
        squaresmove={hero.squaresmove}
      />
      <Container
        sx={{
          my: 4,
        }}
      >
        <Grid
          as={`div`}
          className={`content`}
          gap={[0, 2]}
          columns={[1, `16rem 1fr`, `16rem 1fr`, 2, `36rem 1fr`]}
        >
          <Box>
            <h2>Send us a mail</h2>
            <Themed.p>sayhello@nemeda.io</Themed.p>
            <p>42 Crecy Court, 8 Hotspur Street<br />SE11 6BS, London<br />United Kingdom</p>
          </Box>
          <Form />
        </Grid>
      </Container>
    </Layout>
  )
}

export default Contact

export const query = graphql`
  query Contact {
    site {
      siteMetadata {
        title
        siteTitleAlt
      }
    }
  }
`
