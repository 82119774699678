import React from "react"
import { Box, Button, Checkbox, Label, Input, Textarea, Radio } from "theme-ui"

const Form = () => {

  return (
    <Box
      as={`form`}
      className="form"
      action="https://getform.io/f/5db9f713-b461-4c77-91c3-c7c5cf3b750a" method="POST"
    >
      <Box mb={3}>
        <h2>What's your need?</h2>
        <Label>
          <Radio name='need' /> Partnership with Nemeda
        </Label>
        <Label>
          <Radio name='need' /> I want help with a digital solution
        </Label>
        <Label>
          <Radio name='need' /> Get in touch
        </Label>
      </Box>
      <Label htmlFor='username'>What's your name? *</Label>
      <Input
        type='text'
        name='username'
        id='username'
        mb={3}
      />
      <Label htmlFor='email'>What's your email address? *</Label>
      <Input
        type='email'
        name='email'
        id='email'
        mb={3}
      />
      <Label htmlFor='companyname'>What's your company?</Label>
      <Input
        type='text'
        name='companyname'
        id='companyname'
        mb={3}
      />
      <Box>
        <Label mb={3}>
          <Checkbox />
          Do you prefer to be contacted by phone?
        </Label>
      </Box>
      <Label htmlFor='message'>How can we help? *</Label>
      <Textarea
        name='message'
        id='message'
        rows='6'
        mb={3}
      />
      <Button
        variant='secondary'
        type='submit'
      >
        Submit
      </Button>
    </Box>
  )

}

export default Form
